<template>
  <div>
    <v-card v-if="data" outlined>
      <v-list-item
        class="px-0"
        @mouseover="pipHover = true"
        @mouseleave="pipHover = false"
      >
        <v-img
          :lazy-src="`${baseUrl}thumbnails/${getThumbnail}`"
          max-width="100%"
          :src="`${baseUrl}thumbnails/${getThumbnail}`"
        />
        <div v-if="pipHover" class="options">
          <div>
            <div class="edit my-2">
              <v-btn
                @click="show = true"
                color="accent"
                elevation="1"
                fab
                small
              >
                <v-icon>mdi-pencil</v-icon>
              </v-btn>
            </div>
            <div v-if="deleteMode" class="delete my-2">
              <v-btn color="red" elevation="1" fab small>
                <v-icon color="white">mdi-delete-forever</v-icon>
              </v-btn>
            </div>
          </div>
        </div>
      </v-list-item>
      <h4 class="pa-5">{{ description }}</h4>
    </v-card>

    <ImagePickerDialog
      :pdfOnly="pdfOnly"
      :show="show"
      @close="
        () => {
          show = false;
        }
      "
      @selectedImage="selectedImage"
    />
  </div>
</template>

<script>
import config from "@/config";
import ImagePickerDialog from "./ImagePickerDialog";

export default {
  props: {
    image: {
      type: Object,
      default: () => {
        return {};
      }
    },

    description: {
      type: String,
      default: "Description"
    },

    deleteMode: {
      type: Boolean,
      default: false
    },

    pdfOnly: {
      type: Boolean,
      default: false
    }
  },

  components: {
    ImagePickerDialog
  },

  data() {
    return {
      pipHover: false,
      show: false,
      data: {}
    };
  },

  watch: {
    image(value) {
      this.data = value;
    }
  },

  computed: {
    baseUrl() {
      return config.mediaBaseUrl;
    },

    getThumbnail() {
      return this.data.thumbnail;
    }
  },

  methods: {
    selectedImage(value) {
      this.data = value;
      this.$emit("image", value);
    }
  },

  mounted() {
    this.data = this.image;
  }
};
</script>

<style lang="scss" scoped>
.options {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 100%;
  div {
    position: relative;
  }
}
</style>
