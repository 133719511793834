<template>
  <div>
    <v-card id="account-setting-card">
      <!-- tabs -->

      <v-tabs v-model="tab" show-arrows>
        <v-tab v-for="(tab, index) in tabs" :key="index">
          <v-icon size="20" class="me-3">
            {{ tab.icon }}
          </v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>

      <!-- tabs item -->

      <v-tabs-items v-model="tab">
        <v-tab-item v-for="(tab, index) in tabs" :key="index" class="ma-10">
          <v-row>
            <v-col xs="12" sm="9">
              <content-section
                v-if="!loading"
                :title="content.name"
                :html="content.article"
                @title="e => (content.name = e)"
                @html="e => (content.article = e)"
              />
            </v-col>
            <v-col xs="12" sm="3">
              <image-card
                v-if="content.coverImage"
                :image="content.coverImage"
                description="Cover image"
                @image="
                  e => {
                    content.coverImage = e;
                  }
                "
              />

              <image-card
                v-if="content.file"
                :image="content.file"
                description="Resource - PDF"
                class="mt-5"
                pdfOnly
                @image="
                  e => {
                    content.file = e;
                  }
                "
              />
            </v-col>
          </v-row>
          <v-divider />

          <!-- xxx -->

          <span
            v-for="(item, index) in content.sections"
            :key="`section-${index}`"
          >
            <v-row>
              <v-col xs="12" sm="9">
                <v-card class="mx-auto my-5" outlined>
                  <v-list-item three-line>
                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        Section #{{ index + 1 }}
                      </div>

                      <content-section
                        v-if="!loading"
                        :html="item.article"
                        @html="e => (item.article = e)"
                        :edit="false"
                        hiddeTitle
                      />

                      <v-btn
                        @click="deleteSection(index)"
                        block
                        color="red"
                        elevation="2"
                        outlined
                        plain
                        >Delete</v-btn
                      >
                    </v-list-item-content>
                  </v-list-item>
                </v-card>
              </v-col>

              <v-col xs="12" sm="3" class="py-5">
                <render-images
                  :images="item.files"
                  :max="2"
                  @getImages="e => updateImages(e, index)"
                  custom
                />
              </v-col>
            </v-row>
          </span>

          <v-btn type="dashed" style="width: 100%" @click="addSection">
            <v-icon>mdi-plus</v-icon> Add section
          </v-btn>
        </v-tab-item>
      </v-tabs-items>
    </v-card>

    <div class="d-flex justify-space-between align-center my-4">
      <small>Remember to save the changes before switch the language !</small>
      <v-btn @click="save" x-large color="success" dark>
        Save changes
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import config from "@/config";
import ContentSection from "@/components/ContentSection";
import RenderImages from "@/components/RenderImages";
import ImageCard from "@/components/ImageCard";

export default {
  components: {
    ContentSection,
    RenderImages,
    ImageCard
  },

  data() {
    return {
      lng: 0,
      loading: true,
      content: {},
      tab: 0,
      tabs: [
        { title: "English", lng: "en", icon: "mdi-flag-outline" },
        { title: "Polish", lng: "pl", icon: "mdi-flag-outline" },
        { title: "Russian", lng: "ru", icon: "mdi-flag-outline" }
      ]
    };
  },

  watch: {
    tab(e) {
      this.loadData();
    }
  },

  computed: {
    ...mapGetters({
      getLanguage: "auth/defaultLanguage"
    }),

    getTab() {
      return this.tabs.find((item, index) => index === this.tab);
    },

    baseUrl() {
      return config.mediaBaseUrl;
    }
  },

  methods: {
    ...mapActions({
      getOneById: "products/getOneById",
      update: "products/update"
    }),

    updateImages(data, index) {
      this.content.sections[index].files = [];
      this.content.sections[index].files = data;
    },

    deleteSection(index) {
      const { sections } = this.content;

      if (index > -1) {
        sections.splice(index, 1);
      }
    },

    addSection() {
      const { sections } = this.content;
      sections.push({ article: "New section", files: [] });
    },

    async loadData() {
      const { lng } = this.getTab;
      const { id } = this.$route.params;

      if (lng) {
        const data = await this.getOneById({ lng, id });

        this.content = JSON.parse(JSON.stringify(data));
        this.loading = false;
      }
    },

    save() {
      try {
        const { content } = this;

        const formData = {
          id: content.id,
          lng: content.lng,
          name: content.name,
          file: content.file.id,
          coverImage: content.coverImage,
          coverImageId: content.coverImage.id,
          value: JSON.stringify({
            article: content.article,
            sections: content.sections
          }),
          categoryId: content.categoryId
        };

        this.update(formData);

        this.$toast.success("Content successful updated");
      } catch (error) {
        this.$toast.error(error);
      }
    }
  },

  mounted() {
    this.tab = this.getLanguage.id;
    this.loadData();
  }
};
</script>
